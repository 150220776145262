import { Auth0Provider } from '@auth0/auth0-react'
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import { ExtraErrorData } from '@sentry/integrations'
import * as Sentry from '@sentry/react'
import { Integrations as TracingIntegrations } from '@sentry/tracing'
import { GeneralError } from '@wanda-space/noelle'
import { ApiError, getEnv, theme } from '@wanda/shared'
import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import store from 'store'

import App from './components/App/App'

const environment =
  {
    'bridge.dev.wanda.space': 'dev',
    'bridge.wanda.space': 'production',
  }[window.location.hostname] || 'unknown'

Sentry.init({
  dsn: 'https://58fa9e87bb994621bd7839417efc7a9d@o987876.ingest.sentry.io/5977524',
  integrations: [new TracingIntegrations.BrowserTracing(), new ExtraErrorData()],
  environment,
  release: VERSION,
  enabled: ['dev', 'staging', 'production'].includes(environment),
  tracesSampleRate: 0.1,
  beforeSend(event, hint?) {
    if (hint?.originalException instanceof ApiError && hint.originalException.status() < 500) {
      return null
    }
    if (hint?.originalException?.toString()?.includes('ChunkLoadError')) {
      event.fingerprint = ['ChunkLoadError']
    }
    return event
  },
})

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)

root.render(
  <StrictMode>
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <Sentry.ErrorBoundary
          fallback={({ error }) =>
            error.toString().includes('ChunkLoadError') ? (
              <GeneralError
                text="Looks like we have a new version of bridge!"
                buttonText="Reload to start using it"
                onClick={() => location.reload()}
              />
            ) : (
              <GeneralError buttonText={'Reload'} onClick={() => location.reload()} />
            )
          }
        >
          <Auth0Provider
            authorizationParams={{
              audience: getEnv('AUTH0_AUDIENCE'),
              redirectUri: `${window.location.protocol}//${window.location.host}`,
              prompt: 'select_account',
            }}
            clientId={getEnv('AUTH0_CLIENT_ID')}
            domain={getEnv('AUTH0_DOMAIN')}
          >
            <Provider store={store}>
              <App />
            </Provider>
          </Auth0Provider>
        </Sentry.ErrorBoundary>
      </ChakraProvider>
    </BrowserRouter>
  </StrictMode>,
)
